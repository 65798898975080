import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from "react-router-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import localStorage from 'local-storage';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import RootReducer from './reducers/RootReducer';
import 'bootstrap/dist/css/bootstrap.min.css';



const loadState = () => {
    const defaultState = { msisdn: null };

    try {
        const serializedState = localStorage.get('state');

        console.log(serializedState);
        

        if (serializedState === null) {
            return defaultState;
        }

        return JSON.parse(serializedState);
    } catch (err) {
        console.log(err);
        return defaultState;
    }
};


const saveState = (state) => {
    try {
        console.log(state)
        const serializedState = JSON.stringify(state);
        localStorage.set('state', serializedState);
    } catch {
        // ignore write errors
    }
};


const persistedState = loadState();
console.log(persistedState)

const store = createStore(RootReducer, persistedState);

store.subscribe(() => {
    saveState(store.getState());
});

ReactDOM.render(
    <Provider store={store}>
        <Router><App route="/" /></Router>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
