import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import logo from '../images/logo.png';

const mapStateToProps = state => {
    const { msisdn, user = null, balance, loading } = state;

    return { msisdn, user, balance, loading }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch
    }
}

class Header extends Component {

    constructor(props) {
        super(props);

        const defaultState = {
            navExpanded: true
        };

        this.state = defaultState;
    }

    setNavExpanded = (expanded) => {
        console.log('setNavExpanded');
        console.log(expanded);
        this.setState({ navExpanded: expanded });
    }

    closeNav = () => {
        console.log('closeNav');
        this.setState({ navExpanded: false });
    }

    render() {
        return (
            <header className="text-center">
                <div className="Navbar">
                    <div className="navbar-left">
                        <div className="container text-left">
                            <div className="navbar-menu hidden">
                                <img src={logo} alt="logo" className="logo"/>
                                <Link className="fadeInUp focused" to="/"><span className="fadeInUp">Home</span></Link>
                                <Link className="fadeInUp" to="/help/"><span className="fadeInUp">Help</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-right">
                        <div className="navbar-toggle">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </header >
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
