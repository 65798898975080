import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

class Footer extends Component {

    render() {
        return (
            <footer className="text-center">
                <p className="text-info">The statistics are taken from Ministry of Public Health & WHO (World Health Organization).</p>
                <p className="text-help">Made with Help of <a href="https://www.covid19india.org/">COVID-19 India</a>.</p>
            </footer>
        );
    }
}
export default withRouter(Footer);
