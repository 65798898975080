const RootReducer = (state = {}, action) => {
    let newState = {};

    switch (action.type) {

        case 'LOADING':
            {
                let { loading } = action;

                newState = {
                    ...state,
                    loading
                };

                return newState;
            }

        case 'SET_MSISDN':
            {
                let { msisdn } = action;

                newState = {
                    ...state,
                    msisdn
                };

                return newState;
            }

        case 'SET_USER':
            {
                let { user } = action;

                newState = {
                    ...state,
                    user
                };

                return newState;
            }

        case 'SET_BALANCE':
            {
                let { balance } = action;

                newState = {
                    ...state,
                    balance
                };

                return newState;
            }
            
            case 'PROFILE_PIC_PATH':
                {
                    let {profilepath} = action;
    
                    newState = {
                        ...state,
                        profilepath
                    };
    
                    return newState;
                }
        case 'LOGOUT': {
            return {
                msisdn: null,
                loading : false
            };
        }

        default:
            return state;
    }
};

export default RootReducer;