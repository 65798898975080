import React, { useState, useEffect } from 'react';

function Level(props) {
  const [states, setStates] = useState(props.states);

  useEffect(() => {
    setStates(props.states);
  });

  const totals = states[0];

  if (!totals) {
    return null;
  }

  const { confirmed, active, recovered, deaths, delta } = totals;

  return (
    <div className="Level fadeInUp" style={{ animationDelay: '0.8s' }}>

      <div className="level-item is-cherry">
        <h5>Confirmed</h5>
        <h4 className="my-1">[{delta ? delta.confirmed >= 0 ? '+' + delta.confirmed : '+0' : ''}]</h4>
        <h1>{confirmed} </h1>
      </div>

      <div className="level-item is-blue">
        <h5 className="heading">Active</h5>
        <h4 className="my-1">[{delta ? delta.active >= 0 ? '+' + delta.active : '+0' : ''}]</h4>
        <h1 className="title has-text-info">{active}</h1>
      </div>

      <div className="level-item is-green">
        <h5 className="heading">Recovered</h5>
        <h4 className="my-1">[{delta ? delta.recovered >= 0 ? '+' + delta.recovered : '+0' : ''}]</h4>
        <h1 className="title has-text-success">{recovered} </h1>
      </div>

      <div className="level-item is-gray">
        <h5 className="heading">Deceased</h5>
        <h4 className="my-1">[{delta ? delta.deaths >= 0 ? '+' + delta.deaths : '+0' : ''}]</h4>
        <h1 className="title has-text-grey">{deaths}</h1>
      </div>

    </div>
  );
}

export default Level;
