import ReactLoading from "react-loading";
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { loading = false } = state;

    return { loading }
}

let Loader = ({ loading }) => {
    const style = {
        display: "inline-block",
    }

    return loading && (
        <div className="loader-page" style={style}>
            <ReactLoading type={'bubbles'} color="#000" />
        </div>
    );
}

export default connect(mapStateToProps)(Loader);
