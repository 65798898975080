import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { zonedTimeToUtc } from 'date-fns-tz';
import { formatDistance } from 'date-fns';

import Table from '../Components/table';
import Level from '../Components/level';
import MapExplorer from '../Components/mapexplorer';
import TimeSeries from '../Components/timeseries';
import Minigraph from '../Components/minigraph';
import { API_BASE_URL } from '../constants';

function Home(props) {
  const [states, setStates] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [graphOption, setGraphOption] = useState(1);
  const [lastUpdated, setLastUpdated] = useState('');
  const [timeseries, setTimeseries] = useState([]);
  const [deltas, setDeltas] = useState([]);
  const [timeseriesMode, setTimeseriesMode] = useState(true);
  const [stateHighlighted, setStateHighlighted] = useState(undefined);

  useEffect(() => {
    getStates();
    if (fetched === false) {
      //getStates();
    }
  }, [fetched]);

  const getStates = async () => {
    try {
      const [response] = await Promise.all([axios.get(`${API_BASE_URL}/stats.json`)]);
      setStates(response.data.statewise);
      setTimeseries(response.data.cases_time_series);
      setLastUpdated(response.data.key_values[0].lastupdatedtime);
      setDeltas(response.data.statewise[0].delta);
      setFetched(true);
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (unformattedDate) => {
    const day = unformattedDate.slice(0, 2);
    const month = unformattedDate.slice(3, 5);
    const year = unformattedDate.slice(6, 10);
    const time = unformattedDate.slice(11);
    return `${year}-${month}-${day}T${time}`;
  };

  const onHighlightState = (state, index) => {
    if (!state && !index) setStateHighlighted(null);
    else setStateHighlighted({ state, index });
  };

  let lastUpdatedStr = '';

  if (!isNaN(Date.parse(formatDate(lastUpdated)))) {
    const lastUpdatedInt = zonedTimeToUtc(new Date(formatDate(lastUpdated)), 'Asia/Calcutta');
    lastUpdatedStr = formatDistance(lastUpdatedInt, zonedTimeToUtc(new Date())) + ' Ago';
  }

  return (
    <div className="Home container">
      <div className="home-left">
        <div className="pad-left">
          <div className="header fadeInUp" style={{ animationDelay: '0.5s' }}>
            <div className="header-mid">
              <div className="titles">
                <h1>Afghanistan COVID-19 Tracker</h1>
                <h6>A Bayat Group Initiative</h6>
              </div>
              <div className="last-update">
                <h6>Last Updated</h6>
                <h3>{lastUpdatedStr}</h3>
              </div>
            </div>
          </div>

          <Level states={states} />
          <Minigraph timeseries={timeseries} animate={true} />

          {fetched && (
            <React.Fragment>
              <MapExplorer states={states} stateHighlighted={stateHighlighted} />
            </React.Fragment>
          )}

          {fetched && (
            <React.Fragment>
              <div className="timeseries-header fadeInUp" style={{ animationDelay: '1.5s' }} >
                <h1>Spread Trends</h1>

                <div className="tabs float-left">
                  <div className={`tab ${graphOption === 1 ? 'focused' : ''}`}
                    onClick={() => {
                      setGraphOption(1);
                    }}
                  >
                    <h4>Cumulative</h4>
                  </div>
                  <div className={`tab ${graphOption === 2 ? 'focused' : ''}`}
                    onClick={() => {
                      setGraphOption(2);
                    }} >
                    <h4>Daily</h4>
                  </div>
                </div>

                <div className="timeseries-mode float-right">
                  <label htmlFor="timeseries-mode">Scale Uniformly</label>
                  <input type="checkbox" checked={timeseriesMode}
                    onChange={(event) => {
                      setTimeseriesMode(!timeseriesMode);
                    }} />
                </div>
              </div>

              <TimeSeries timeseries={timeseries} type={graphOption} mode={timeseriesMode} />
            </React.Fragment>
          )}
        </div>
      </div>

      <div className="home-right">
        <div className="pad-right">
          <Table states={states} summary={false} onHighlightState={onHighlightState} />
        </div>
      </div>
    </div>
  );
}

export default Home;
