import React, { Component, Fragment } from "react";

import cough from '../images/symptoms-cough.jpg';
import fever from '../images/symptoms-fever.jpg';
import shortness from '../images/symptoms-shortness-breath.jpg';

class Help extends Component {
    render() {
        return (
            <Fragment>
                <div className="jumbotron">
                    <div className="container">
                        <h1 className="title  text-left abouth1 mb-0">Help</h1>
                    </div>
                </div>
                <br />
                <div className="container form text-left about-container">
                    <div className="row">
                        <div className="col-md-12 pb-4">
                            <h4>Bayat Group Emergency Hotline: 0701107333</h4>
                            <h4>Email: medical.team@bayat-group.com</h4>
                            <br />
                            <div className="embed-responsive embed-responsive-16by9 col-md-5">
                                <iframe height="300" title="help" width="500" src="https://www.youtube.com/embed/IMvBgrs_mfY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <br />
                            <h2>What Is Coronavirus Disease 2019 (COVID-19)?</h2>

                            <div className="article-body" data-metrics-module="embd-lnk">
                                <div className="article-page active-page" data-page="1">
                                    <section>
                                        <p>A coronavirus is a kind of common virus that causes an infection in your nose, sinuses, or upper throat.They’re called coronaviruses because under a microscope, they look like a crown.</p>
                                    </section>

                                    <section>
                                        <h2 id="1-4">What Are the Symptoms of COVID-19?</h2>
                                        <p>Early symptoms include:</p>
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="img-thumbnail" src={fever} alt="fever" />
                                            </div>

                                            <div className="col-4">
                                                <img className="img-thumbnail" src={cough} alt="cough" />
                                            </div>

                                            <div className="col-4">
                                                <img className="img-thumbnail" src={shortness} alt="Shortness of breath" />
                                            </div>
                                        </div>
                                        <br />

                                        <p>The virus can lead to pneumonia, respiratory failure, septic shock, and death. If you notice these severe symptoms in yourself or a loved one, get medical attention right away:</p>
                                        <ul>
                                            <li>Trouble breathing or shortness of breath</li>
                                            <li>Ongoing chest pain or pressure</li>
                                            <li>New confusion</li>
                                            <li>Can’t wake up</li>
                                            <li>Bluish lips or face</li>
                                        </ul>
                                        <p>If you’re exposed and infected, symptoms can show up in as few as 2 days or as many as 14. It varies from person to person</p>
                                        <p>The most common symptoms and the percentage of people who have them include:</p>
                                        <ul>
                                            <li>Fever: 88%</li>
                                            <li>Dry cough: 68%</li>
                                            <li>Fatigue: 38%</li>
                                            <li>Coughing up sputum, or thick phlegm, from the lungs: 33%</li>
                                            <li>Shortness of breath: 19%</li>
                                            <li>Bone or joint pain: 15%</li>
                                            <li>Sore throat: 14%</li>
                                            <li>Headache: 14%</li>
                                            <li>Chills: 11%</li>
                                            <li>Nausea or vomiting: 5%</li>
                                            <li>Stuffy nose: 5%</li>
                                            <li>Diarrhea: 4%</li>
                                            <li>Coughing up blood: 1%</li>
                                            <li>Swollen eyes: 1%</li>
                                        </ul>
                                    </section>
                                </div>

                                <div className="article-page active-page" data-page="3">
                                    <section>
                                        <h2 id="3-10">How Do You Prevent the Spread?</h2>
                                        <p>If you’re in an area where it’s spreading, take these steps:</p>
                                        <ul>
                                            <li><strong>Wash your hands often with soap and water, or clean them with an alcohol-based sanitizer.</strong></li>
                                            <li><strong>Practice social distancing.</strong></li>
                                            <li><strong>Don’t touch your face.</strong></li>
                                            <li><strong>Clean and disinfect.</strong></li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses">WHO Guidelines</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        );
    }
}

export default Help;
