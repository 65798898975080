import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";


import './App.scss';

import Footer from './Components/Footer';
import Header from './Components/Header';
import Loader from "./Components/Loader";
import Banner from './Components/Banner';


/** */
import Help from './Pages/Help';
import Index from "./Pages/Index";



class App extends Component {

    render() {
        const { location } = this.props;

        return (
            <Fragment>
                <Header />
                <Banner/>
                <div id={location.pathname !== '/' ? "content" : 'home'} className="App">
                    <Loader />
                    <Switch>
                        <Route exact path="/" component={Index} />
                        <Route path="/help/" component={Help} />
                    </Switch>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

export default withRouter((App));
