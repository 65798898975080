import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';

function Row(props) {
  const [state, setState] = useState(props.state);

  useEffect(() => {
    setState(props.state);
  }, [props.state]);

  const { confirmed = 0, recovered = 0, deaths = 0 } = state
  let active = confirmed - recovered - deaths;

  return (
    <React.Fragment>
      <tr className={props.total ? 'state is-total' : 'state'} className={props.total ? 'is-total' : ''}
        onMouseEnter={() => props.onHighlightState?.(state, props.index)}
        onMouseLeave={() => props.onHighlightState?.()}
      >
        <td style={{ fontWeight: 600 }}>{state.state}</td>
        <td>
          <span className="deltas" style={{ color: '#ff073a' }}>
            {!state.delta.confirmed == 0 && <Icon.ArrowUp />}
            {state.delta.confirmed > 0 ? `${state.delta.confirmed}` : ''}
          </span>
          {parseInt(confirmed) <= 0 ? '-' : confirmed}
        </td>
        <td style={{ color: parseInt(active) <= 0 ? '#B5B5B5' : 'inherit' }}>{parseInt(active) <= 0 ? '-' : active}</td>
        <td style={{ color: parseInt(recovered) <= 0 ? '#B5B5B5' : 'inherit' }}>{parseInt(recovered) <= 0 ? '-' : recovered}</td>
        <td style={{ color: parseInt(deaths) <= 0 ? '#B5B5B5' : 'inherit' }}>{parseInt(deaths) <= 0 ? '-' : deaths}</td>
      </tr>

      <tr className={`spacer`} style={{ display: props.reveal && !props.total ? '' : 'none' }}>
        <td></td>
        <td></td>
        <td></td>
      </tr>

      <tr className={`spacer`} style={{ display: props.reveal && !props.total ? '' : 'none' }}>
        <td></td>
        <td></td>
        <td></td>
      </tr>

    </React.Fragment>
  );
}

export default Row;
